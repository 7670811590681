var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.$route.query.room_id
        ? _c("div", { staticClass: "relative", attrs: { id: "map-wrap" } }, [
            _c(
              "div",
              {
                staticClass: "flex-1 p-2 justify-between flex flex-col",
                staticStyle: { height: "calc(72vh - 0px)" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex sm:items-center justify-between pb-3 pt-0 border-b-2 border-gray-200",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "relative flex items-center space-x-4" },
                      [
                        _vm.currentRoom.attributes
                          ? _c("div", { staticClass: "relative" }, [
                              _c("img", {
                                staticClass:
                                  "w-8 sm:w-12 h-8 sm:h-12 rounded-full",
                                attrs: {
                                  src: _vm.consultant.avatar
                                    ? _vm.$tools.getFileUrl(
                                        _vm.consultant.avatar
                                      )
                                    : require("/assets/images/person/avatar.jpg"),
                                  alt: "",
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex flex-col leading-tight" },
                          [
                            _c(
                              "div",
                              { staticClass: "text-lg mt-1 flex items-center" },
                              [
                                _vm.consultant.username
                                  ? _c(
                                      "span",
                                      { staticClass: "text-gray-700 mr-3" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            `${
                                              _vm.consultant.name
                                                ? _vm.consultant.name
                                                : ""
                                            } ${
                                              _vm.consultant.surname
                                                ? _vm.consultant.surname
                                                : ""
                                            }`
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.consultant.consultantcategory
                              ? _c(
                                  "span",
                                  { staticClass: "text-sm text-gray-600" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.consultant.consultantcategory.name
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch",
                    attrs: { id: "messages" },
                  },
                  [
                    _vm._l(_vm.messages, function (msg, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "chat-message" },
                        [
                          (
                            msg.attributes.sender
                              ? msg.attributes.sender.data.id ==
                                _vm.currentUser.id
                                ? true
                                : false
                              : false
                          )
                            ? _c(
                                "div",
                                { staticClass: "flex items-end justify-end" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.handleClick($event, msg)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "px-4 py-2 rounded-lg inline-block rounded-br-none text-gray-600 text-sm bg-blue-100",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "bg-indigo-300 mb-1",
                                            },
                                            [
                                              msg.attributes.filepath
                                                ? _c("img", {
                                                    staticClass:
                                                      "object-cover h-48 w-96",
                                                    attrs: {
                                                      src: `${_vm.$tools.getFileUrl(
                                                        msg.attributes.filepath
                                                      )}`,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("span", {}, [
                                            _vm._v(_vm._s(msg.attributes.text)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticClass: "w-6 h-6 rounded-full order-2",
                                    attrs: {
                                      src:
                                        msg.attributes.sender &&
                                        msg.attributes.sender.data.attributes
                                          .avatar
                                          ? _vm.$tools.getFileUrl(
                                              msg.attributes.sender.data
                                                .attributes.avatar
                                            )
                                          : require("/assets/images/person/avatar.jpg"),
                                      alt: "My profile",
                                    },
                                    on: {
                                      error: function ($event) {
                                        return require("/assets/images/person/avatar.jpg")
                                      },
                                    },
                                  }),
                                ]
                              )
                            : _c("div", { staticClass: "flex items-end" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start bg-gray-300 rounded-t-lg rounded-r-lg",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "px-4 py-2 rounded-lg inline-block rounded-bl-none text-gray-600 bg-orange-50",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "bg-indigo-300 mb-1" },
                                          [
                                            msg.attributes.filepath
                                              ? _c("img", {
                                                  staticClass:
                                                    "object-cover h-48 w-96",
                                                  attrs: {
                                                    src: `${_vm.$tools.getFileUrl(
                                                      msg.attributes.filepath
                                                    )}`,
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(msg.attributes.text)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "w-6 h-6 rounded-full order-1",
                                  attrs: {
                                    src:
                                      msg.attributes.sender &&
                                      msg.attributes.sender.data.attributes
                                        .avatar
                                        ? _vm.$tools.getFileUrl(
                                            msg.attributes.sender.data
                                              .attributes.avatar
                                          )
                                        : require("/assets/images/person/avatar.jpg"),
                                    alt: "My profile",
                                  },
                                  on: {
                                    error: function ($event) {
                                      return require("/assets/images/person/avatar.jpg")
                                    },
                                  },
                                }),
                              ]),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "chat-message" }),
                  ],
                  2
                ),
                _vm._v(" "),
                !_vm.currentRoom.attributes.isCompleted ||
                _vm.$route.query.room_id === "new"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0",
                      },
                      [
                        _c("div", { staticClass: "relative flex" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.message.text,
                                expression: "message.text",
                              },
                            ],
                            staticClass:
                              "w-full focus:outline-none focus:border-blue-300 pr-20 px-2 focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-200 rounded-md py-3",
                            attrs: {
                              rows: 1,
                              placeholder: _vm.$t("write-a-message"),
                            },
                            domProps: { value: _vm.message.text },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.sendMessage()
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.message,
                                  "text",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "absolute right-0 items-center inset-y-0 flex",
                            },
                            [
                              _vm.currentUser.role &&
                              _vm.currentUser.role.id === 4
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeChatRoom()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticStyle: {
                                            "enable-background":
                                              "new 0 0 32 32",
                                          },
                                          attrs: {
                                            version: "1.1",
                                            id: "Uploaded to svgrepo.com",
                                            xmlns: "http://www.w3.org/2000/svg",
                                            "xmlns:xlink":
                                              "http://www.w3.org/1999/xlink",
                                            x: "0px",
                                            y: "0px",
                                            width: "32px",
                                            height: "32px",
                                            viewBox: "0 0 32 32",
                                            "xml:space": "preserve",
                                          },
                                        },
                                        [
                                          _c("g", [
                                            _c("path", {
                                              staticClass: "duotone_een",
                                              attrs: {
                                                d: "M24.232,8.626l-9.192,9.192c-0.194,0.194-0.513,0.194-0.707,0l-2.828-2.828\n\t\tc-0.194-0.194-0.513-0.194-0.707,0l-0.707,0.707c-0.194,0.194-0.194,0.513,0,0.707l4.243,4.243c0.194,0.194,0.513,0.194,0.707,0\n\t\tL25.646,10.04c0.194-0.194,0.194-0.513,0-0.707l-0.707-0.707C24.745,8.431,24.427,8.431,24.232,8.626z",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              staticClass: "duotone_twee",
                                              attrs: {
                                                d: "M15.747,21.354c-0.586,0.586-1.536,0.585-2.121,0c-0.038-0.038-0.192-0.192-4.243-4.243\n\t\tc-0.586-0.585-0.586-1.536,0-2.121l0.707-0.707c0.585-0.585,1.536-0.585,2.121,0l2.475,2.475L22,9.444V8.5\n\t\tC22,8.225,21.775,8,21.5,8h-15C6.225,8,6,8.225,6,8.5v15C6,23.775,6.225,24,6.5,24h15c0.275,0,0.5-0.225,0.5-0.5v-8.399\n\t\tL15.747,21.354z",
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        ref: "file",
                                        staticClass: "hidden",
                                        attrs: {
                                          type: "file",
                                          accept: "image/*",
                                        },
                                        on: { change: _vm.mediaChange },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.file.click()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "h-6 w-6 text-gray-600",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        fill: "none",
                                        viewBox: "0 0 24 24",
                                        stroke: "currentColor",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "stroke-width": "2",
                                          d: "M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    ref: "file",
                                    staticClass: "hidden",
                                    attrs: { type: "file", accept: "image/*" },
                                    on: { change: _vm.mediaChange },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "text-blue-400 hover:bg-[#169d245a] hover:text-[#169D23] inline-flex items-center mr-1 justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out focus:outline-none",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendMessage()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "h-6 w-6 ml-2 transform rotate-90",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 20 20",
                                        fill: "currentColor",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : !_vm.currentRoom.attributes.rate &&
                    _vm.currentUser.role.id !== 4
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex items-center justify-between" },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("evaluate-the-advice"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex ml-4 items-center" },
                              [
                                _c("star-rating", {
                                  model: {
                                    value: _vm.advice.rating,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.advice, "rating", $$v)
                                    },
                                    expression: "advice.rating",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "items-center flex" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "text-blue-400 bg-blue-100 hover:bg-geen-300 px-2 py-1 hover:text-[#169D23] inline-flex items-center mr-1 justify-center rounded-md transition duration-500 ease-in-out focus:outline-none",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toRating()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("rating")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0",
                      },
                      [
                        _c("div", { staticClass: "align-middle text-center" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "rounded-md py-1 px-2 bg-blue-200 text-gray-600",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("chat-room-closed")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
              ]
            ),
          ])
        : _c("div", [
            _c("div", { staticClass: "align-middle text-center" }, [
              _c(
                "span",
                {
                  staticClass: "rounded-md py-1 px-2 bg-blue-200 text-gray-600",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("select-chat-to-messaging")) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
      _vm._v(" "),
      _c("vue-simple-context-menu", {
        ref: "vueSimpleContextMenu",
        attrs: { "element-id": "myUniqueId", options: _vm.options },
        on: { "option-clicked": _vm.optionClicked },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }