var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "group w-full hover:shadow-md relative" }, [
    _c(
      "div",
      {
        staticClass: "flex items-center w-full bg-white rounded-md gap-2 h-36",
      },
      [
        _vm.data
          ? _c("img", {
              staticClass: "h-full mx-auto rounded-md object-contain",
              attrs: { src: _vm.data },
            })
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }