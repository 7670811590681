<template>
  <div class="p-5">
    <div v-if="isLoggedIn" class="relative parent-class">
      <div class="flex items-center">
        <button
          id="user-menu-button"
          type="button"
          class="justify-end inline-flex items-center py-2 text-sm font-medium focus:outline-none border border-transparent bg-light-gray text-gray-600 lg:rounded-md lg:hover:bg-gray-50"
          aria-expanded="false"
          aria-haspopup="true"
          @click="openProfile"
        >
          <img
            class="w-10 h-10 object-cover flex justify-end rounded-full"
            alt="Avatar"
            :src="
                      currentUser.avatar
                        ? $tools.getFileUrl(currentUser.avatar)
                        : require('/assets/images/person/avatar.jpg')
                    "
            @error="currentUser.avatar = require('/assets/images/person/avatar.jpg')"
          />
          <div
            class="block font-medium hover:bg-gray-100 px-4 py-2 text-sm text-gray-600 cursor-pointer"
          >
            <span>{{
                `${currentUser.name ? currentUser.name : ''} ${
                  currentUser.surname ? currentUser.surname : ''
                }`
              }}</span>
            <p class="text-xs text-left text-gray-500">ID: {{ currentUser.id }}</p>
          </div>
        </button>
      </div>
    </div>
    <div
      v-if='isLoggedIn'
      @click="toMyChats"
      class="block font-medium py-2 text-sm text-gray-600 hover:bg-gray-100 cursor-pointer"
    >
      {{ $t('my-chats') }}
    </div>
    <!-- <button
      v-else
      class="text-white focus:outline-none text-sm rounded-md bg-[#169D23] p-3"
      @click="
        signIn()
        closePanel()
      "
    >
      {{ $t('login-register') }}
    </button> -->
    <div v-for="(menu, index) in navbar" :key="index" class="flex items-center">
      <div v-if="menu.children">
        <div class="block my-3">
          <a
            :class="
              $route.path.search(menu.route) > 0
                ? 'text-base text-[#169D23] font-semibold'
                : 'text-gray-800 hover:text-[#169D23] font-medium text-base '
            "
            @click="
              menuWithChildClicked(menu)
            "
          >
            {{ $t(menu.name) }}
            <i
              class="text-gray-800 text-xl"
              :class="[menu.current ? menu.iconUp : menu.iconDown]"
            />
          </a>
        </div>
        <div v-for="(child, k) in menu.children" v-show="menu.current" :key="k" class="pb-3">
          <div
            v-if="child.route"
            class="block"
            :class="
              child.current
                ? 'cursor-pointer text-gray-800 px-4 text-sm font-medium rounded-md'
                : 'cursor-pointer text-gray-800 px-4 text-sm font-medium rounded-md'
            "
            @click="
              menuChildClicked(menu, child)
              closePanel()
            "
          >
            {{ $t(child.name) }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="block my-3"
        :class="
          $route.path.search(menu.route) > 0
            ? 'text-base text-[#169D23] font-semibold'
            : 'text-gray-800 hover:text-[#169D23] font-medium text-base '
        "
        @click="closePanel()"
      >
        <router-link :to="{ path: localePath(menu.route) }">
          {{ $t(menu.name) }}
        </router-link>
      </div>
    </div>
    <div class="mt-1 space-y-5 text-gray-700">
      <div class="flex items-center gap-2">
        <i class="bx bx-envelope lg:text-lg text-base"></i>
        <p class="text-sm">juanjose.robalino@gggi.org</p>
      </div>
      <!-- <div class="flex items-center gap-2">
        <i class="bx bx-phone lg:text-lg text-base"></i>
        <p class="text-sm">+99894 081 23 45</p>
      </div> -->
      <div class="flex gap-2">
        <i class="bx bx-map lg:text-lg text-base"></i>
        <p class="text-sm">7a, Bunyodkor av., 100000, Tashkent, Uzbekistan</p>
      </div>
    </div>
    <div
      v-if='isLoggedIn'
      class="block flex mt-2 font-medium py-2 text-sm text-red-600 hover:bg-red-100 cursor-pointer"
      @click="logOut()"
    >
      <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="m14.167 13.334 3.333-3.333m0 0-3.333-3.334m3.333 3.334H5.833m5 3.333v.833a2.5 2.5 0 0 1-2.5 2.5H5a2.5 2.5 0 0 1-2.5-2.5V5.834a2.5 2.5 0 0 1 2.5-2.5h3.333a2.5 2.5 0 0 1 2.5 2.5v.833" stroke="#DC2626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <span class='ml-3'>{{ $t('logout') }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { socket } from '~/plugins/socket.client'

export default {
  name: 'MobileMenu',
  data() {
    return {
      navbar: [
        {
          id: 0,
          name: 'home',
          route: '/',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        {
          id: 1,
          name: 'about',
          route: '/about',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        {
          id: 2,
          name: 'training',
          route: '/training',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        // {
        //   id: 3,
        //   name: 'agri-business',
        //   route: '/agri-business',
        //   current: false,
        //   iconDown: 'bx bx-chevron-down',
        //   iconUp: 'bx bx-chevron-up',
        //   children: [
        //     { name: 'agro-location', route: '/agri-business', current: false },
        //     { name: 'agro-tools', route: '/agro-tools', current: false },
        //   ],
        // },
        {
          id: 4,
          name: 'agri-finance',
          route: '/agri-finance',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        // {
        //   id: 5,
        //   name: 'agri-market',
        //   iconDown: 'bx bx-chevron-down',
        //   iconUp: 'bx bx-chevron-up',
        //   current: false,
        // },
        // {
        //   id: 6,
        //   name: 'advisory',
        //   route: '/advisory',
        //   current: false,
        //   iconDown: 'bx bx-chevron-down',
        //   iconUp: 'bx bx-chevron-up',
        // },
      ],
    }
  },
  methods: {
    menuChildClicked(menu, child) {
      menu.children.forEach((ch) => {
        if (child === ch) {
          ch.current = true
        } else {
          ch.current = false
        }
      })
      this.$router.push(this.localePath(child.route))
      return child
    },
    menuWithChildClicked(menu) {
      this.navbar.forEach((navbar) => {
        if (menu === navbar) {
          navbar.current = !navbar.current
          navbar.children.forEach((child) => {
            child.current = false
          })
        } else {
          navbar.current = false
        }
      })
      return menu
    },
    signIn() {
      this.$router.push({
        path: this.localePath('/login'),
      })
    },
    closePanel() {
      this.$emit('closePanel', {})
    },
    async logOut() {
      await socket.emit('leave', {
        username: this.user_name,
        user_id: this.user_id,
      })
      this.isProfileOpened = false
      await localStorage.removeItem('local')
      await localStorage.removeItem('user_info')
      await this.$auth.logout()
      this.closePanel()
      this.$router.push({path: this.localePath('/')})
    },
    toMyChats() {
      this.isProfileOpened = false
      this.closePanel()
      this.$router.push({ path: this.localePath('/chats') })
    },
    openProfile() {
      this.isProfileOpened = !this.isProfileOpened
    },
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.loggedIn,
      currentUser: (state) => state.auth.user,
      user_id: (state) => state.auth.user.id,
      user_name: (state) => state.auth.user.username
    }),
    }
}
</script>
<style>
.child-class {
  display: none;
}
.parent-class:hover .child-class {
  display: block;
}
</style>
