var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.custom
      ? _c("div", [
          _c(
            "div",
            { staticClass: "lg:flex block items-center justify-between" },
            [
              _c("div", { staticClass: "font-semibold text-black text-2xl" }, [
                _c("span", { staticClass: "text-black" }, [
                  _vm._v(_vm._s(_vm.name)),
                ]),
              ]),
              _vm._v(" "),
              !_vm.isConsultant
                ? _c("div", { staticClass: "mt-1 flex rounded-md shadow-sm" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "relative py-0.5 bg-[#169D23] rounded-md rounded-r-none flex items-stretch flex-grow focus-within:z-10 lg:my-0 mt-4",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter.text,
                              expression: "filter.text",
                            },
                          ],
                          staticClass:
                            "block w-full p-2 ml-0.5 focus:outline-none sm:text-sm border-gray-300 border rounded-md",
                          attrs: {
                            id: "search",
                            type: "search",
                            name: "search",
                            placeholder: "Search",
                          },
                          domProps: { value: _vm.filter.text },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.filter, "text", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(0),
                  ])
                : _c(
                    "div",
                    {
                      staticClass:
                        "border lg:block rounded-md border-[#169D23] text-[#169D23] p-2 hidden",
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter.category,
                              expression: "filter.category",
                            },
                          ],
                          staticClass:
                            "font-semibold focus:outline-none flex justify-between w-32",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.filter,
                                  "category",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.onChangeCategory,
                            ],
                          },
                        },
                        [
                          _vm._l(_vm.categories, function (category, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                staticClass: "pr-4",
                                domProps: { value: category.id },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(category.attributes.name) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "bx bx-chevron-down text-lg",
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
            ]
          ),
          _vm._v(" "),
          !_vm.isConsultant
            ? _c(
                "div",
                {
                  staticClass:
                    "lg:flex grid-cols-3 items-center hidden justify-center lg:gap-7 gap-4 my-6",
                },
                _vm._l(_vm.categories, function (category, index) {
                  return _c(
                    "button",
                    {
                      key: index,
                      staticClass:
                        "rounded-md flex items-center focus:outline-none py-1.5 px-4",
                      class:
                        _vm.$route.query.category &&
                        parseInt(_vm.$route.query.category) === category.id
                          ? "bg-[#169D23] text-white"
                          : "border border-[#169D23] bg-[#169D23] hover:text-white hover:bg-[#169D23]",
                      on: {
                        click: function ($event) {
                          return _vm.toChange(category)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            category.attributes ? category.attributes.name : ""
                          ) +
                          "\n        "
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "lg:hidden flex mt-4" },
            [
              _c(
                "swiper",
                {
                  ref: "swiper",
                  staticClass: "swiper",
                  attrs: {
                    options: _vm.categoriesOption,
                    autoplay: { delay: 2000, disableOnIteraction: false },
                    pagination: { clickable: true },
                  },
                },
                [
                  _c(
                    "client-only",
                    _vm._l(_vm.categories, function (category, index) {
                      return _c("swiper-slide", { key: index }, [
                        _c(
                          "button",
                          {
                            staticClass: "rounded-md py-1.5 mb-4 w-full",
                            class:
                              _vm.$route.query.category &&
                              parseInt(_vm.$route.query.category) ===
                                category.id
                                ? "bg-[#169D23] text-white"
                                : "border border-[#169D23] text-[#169D23] hover:text-white hover:bg[#169D23]",
                            on: {
                              click: function ($event) {
                                return _vm.toChange(category)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  category.attributes
                                    ? category.attributes.name
                                    : ""
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _c(
          "div",
          {
            staticClass:
              "flex flex-col rounded-2xl overflow-hidden sm:h-auto bg-[rgb(250,250,250)]",
            class: _vm.dropdownOpen ? "h-auto" : "h-[70px]",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "sm:hidden border-b border-[#D7D0D0] py-6 px-8 cursor-pointer text-[#00730C] font-bold flex justify-between items-center",
                on: {
                  click: function ($event) {
                    _vm.dropdownOpen = !_vm.dropdownOpen
                  },
                },
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.categories.find(
                        (res) => res.id == _vm.$route.query.category
                      )?.attributes?.name
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    class: _vm.dropdownOpen ? "rotate-180 transition-all" : "",
                    attrs: {
                      width: "12",
                      height: "8",
                      viewBox: "0 0 12 8",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M5.99997 7.42017C5.7849 7.42017 5.56987 7.33806 5.4059 7.17417L0.24617 2.01439C-0.0820565 1.68616 -0.0820565 1.154 0.24617 0.825904C0.574263 0.497811 1.10632 0.497811 1.43457 0.825904L5.99997 5.39156L10.5654 0.826063C10.8936 0.49797 11.4256 0.49797 11.7537 0.826063C12.0821 1.15416 12.0821 1.68632 11.7537 2.01455L6.59404 7.17433C6.42999 7.33824 6.21495 7.42017 5.99997 7.42017Z",
                        fill: "#000",
                      },
                    }),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.categories, function (category, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "border-b border-[#D7D0D0] py-6 px-8 cursor-pointer hover:text-[#00730C] font-bold",
                  class:
                    _vm.$route.query.category &&
                    parseInt(_vm.$route.query.category) === category.id
                      ? "sm:text-[#00730C]"
                      : "text-[#0B0B0B]",
                  on: {
                    click: function ($event) {
                      return _vm.toChange(category)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        category.attributes ? category.attributes.name : ""
                      ) +
                      "\n      "
                  ),
                ]
              )
            }),
          ],
          2
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "-ml-px relative inline-flex items-center space-x-2 px-4 py-2 lg:my-0 mt-4 bg-[#169D23] text-sm font-medium rounded-r-md focus:outline-none",
        attrs: { type: "button" },
      },
      [_c("i", { staticClass: "bx bx-search text-lg text-white" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }