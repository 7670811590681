<template>
  <div class="group w-full hover:shadow-md relative">
    <div class="flex items-center w-full bg-white rounded-md gap-2 h-36">
<!--      <img-->
<!--      v-if="data"-->
<!--        :src="-->
<!--          data!== null-->
<!--            ? $tools.getFileUrl(data)-->
<!--            : require('/assets/images/default.png')-->
<!--        "-->
<!--        class="w-full h-16 object-cover rounded-md"-->
<!--      />-->
      <img
        v-if="data"
        :src="data"
        class="h-full mx-auto rounded-md object-contain"
      />
<!--      <div class="grid content-between space-y-2">-->
<!--        <div v-if="data && data.attributes && data.attributes.product && data.attributes.product.data && data.attributes.product.data.attributes" class="text-gray-700 text-sm font-semibold">-->
<!--          {{ data.attributes.product.data.attributes.name }}-->
<!--        </div>-->
<!--        <div v-if="data && data.attributes && data.attributes.district && data.attributes.district.data && data.attributes.district.data.attributes" class="text-green-700 font-medium text-xs">-->
<!--          {{ data.attributes.district.data.attributes.name }}-->
<!--        </div>-->
<!--        <div v-if="data && data.attributes" class="flex items-center text-gray-700 font-semibold text-sm space-x-3">-->
<!--          <div>Min: {{ data.attributes.min }} UZS/kg</div>-->
<!--          <div>Max: {{ data.attributes.max }} UZS/kg</div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prices',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    data: [Object, String, Number],
  },
  }
</script>
