var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lg:col-span-1 col-span-4 flex overflow-hidden w-full" },
    [
      _c("div", { staticClass: "flex flex-shrink-0 border shadow-md w-full" }, [
        _c("div", { staticClass: "flex flex-col w-full" }, [
          _c("div", { staticClass: "flex flex-col h-0 flex-1" }, [
            _c(
              "div",
              { staticClass: "flex-1 flex flex-col overflow-y-auto w-full" },
              [
                _c(
                  "nav",
                  { staticClass: "flex-1 bg-white px-2" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "cursor-pointer group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                        class: _vm.locale.current
                          ? "text-[#169D23] hover:text-[#169D23]"
                          : "text-gray-500 hover:text-gray-700",
                        on: {
                          click: function ($event) {
                            return _vm.menuClicked(_vm.locale)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t(_vm.locale.name[_vm.$i18n.locale])) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.sidebar, function (item, key) {
                      return _c("div", { key: key, staticClass: "border-b" }, [
                        item.attributes.children
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex cursor-pointer justify-between",
                                    on: {
                                      click: function ($event) {
                                        return _vm.menuWithChildClicked(item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "cursor-pointer group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                                        class: item.current
                                          ? "text-[#169D23] hover:text-[#169D23]"
                                          : "text-gray-500 hover:text-gray-700",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(item.attributes.title) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    item.attributes.children.data.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex cursor-pointer justify-end items-center",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "mx-4 h-4 w-4 bx",
                                              class: item.current
                                                ? "bx bx-chevron-up"
                                                : "bx bx-chevron-down",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  item.attributes.children.data,
                                  function (child, k) {
                                    return _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: item.current === true,
                                            expression: "item.current === true",
                                          },
                                        ],
                                        key: k,
                                      },
                                      [
                                        child
                                          ? _c("div", [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "cursor-pointer ml-4 group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                                                  class: child.current
                                                    ? "text-[#169D23] hover:text-[#169D23]"
                                                    : "text-gray-500 hover:text-gray-700",
                                                  attrs: { to: child.route },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.menuChildClicked(
                                                        item,
                                                        child
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        child.attributes.title
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "cursor-pointer group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                                class: item.current
                                  ? "text-[#169D23] hover:text-[#169D23]"
                                  : "text-gray-500 hover:text-gray-700",
                                on: {
                                  click: function ($event) {
                                    return _vm.menuClicked(item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item.attributes.title) +
                                    "\n                "
                                ),
                              ]
                            ),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }