var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex" },
    _vm._l(5, function (i, ind) {
      return _c(
        "button",
        {
          key: ind,
          class: { "mr-1": i < 5 },
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("input", i)
            },
          },
        },
        [
          _c(
            "svg",
            {
              staticClass: "block h-8 w-8",
              class: [
                _vm.value >= i
                  ? "text-[#169D23] border-[#169D23] shadow-sm"
                  : "text-gray-400 hover:text-[#169D23] shadow-sm",
              ],
              attrs: {
                fill: "currentColor",
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 20 20",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z",
                },
              }),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }