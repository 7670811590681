var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-5" },
    [
      _vm.isLoggedIn
        ? _c("div", { staticClass: "relative parent-class" }, [
            _c("div", { staticClass: "flex items-center" }, [
              _c(
                "button",
                {
                  staticClass:
                    "justify-end inline-flex items-center py-2 text-sm font-medium focus:outline-none border border-transparent bg-light-gray text-gray-600 lg:rounded-md lg:hover:bg-gray-50",
                  attrs: {
                    id: "user-menu-button",
                    type: "button",
                    "aria-expanded": "false",
                    "aria-haspopup": "true",
                  },
                  on: { click: _vm.openProfile },
                },
                [
                  _c("img", {
                    staticClass:
                      "w-10 h-10 object-cover flex justify-end rounded-full",
                    attrs: {
                      alt: "Avatar",
                      src: _vm.currentUser.avatar
                        ? _vm.$tools.getFileUrl(_vm.currentUser.avatar)
                        : require("/assets/images/person/avatar.jpg"),
                    },
                    on: {
                      error: function ($event) {
                        _vm.currentUser.avatar = require("/assets/images/person/avatar.jpg")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "block font-medium hover:bg-gray-100 px-4 py-2 text-sm text-gray-600 cursor-pointer",
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            `${
                              _vm.currentUser.name ? _vm.currentUser.name : ""
                            } ${
                              _vm.currentUser.surname
                                ? _vm.currentUser.surname
                                : ""
                            }`
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "text-xs text-left text-gray-500" },
                        [_vm._v("ID: " + _vm._s(_vm.currentUser.id))]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoggedIn
        ? _c(
            "div",
            {
              staticClass:
                "block font-medium py-2 text-sm text-gray-600 hover:bg-gray-100 cursor-pointer",
              on: { click: _vm.toMyChats },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("my-chats")) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.navbar, function (menu, index) {
        return _c("div", { key: index, staticClass: "flex items-center" }, [
          menu.children
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "block my-3" }, [
                    _c(
                      "a",
                      {
                        class:
                          _vm.$route.path.search(menu.route) > 0
                            ? "text-base text-[#169D23] font-semibold"
                            : "text-gray-800 hover:text-[#169D23] font-medium text-base ",
                        on: {
                          click: function ($event) {
                            return _vm.menuWithChildClicked(menu)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t(menu.name)) +
                            "\n            "
                        ),
                        _c("i", {
                          staticClass: "text-gray-800 text-xl",
                          class: [menu.current ? menu.iconUp : menu.iconDown],
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(menu.children, function (child, k) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: menu.current,
                            expression: "menu.current",
                          },
                        ],
                        key: k,
                        staticClass: "pb-3",
                      },
                      [
                        child.route
                          ? _c(
                              "div",
                              {
                                staticClass: "block",
                                class: child.current
                                  ? "cursor-pointer text-gray-800 px-4 text-sm font-medium rounded-md"
                                  : "cursor-pointer text-gray-800 px-4 text-sm font-medium rounded-md",
                                on: {
                                  click: function ($event) {
                                    _vm.menuChildClicked(menu, child)
                                    _vm.closePanel()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t(child.name)) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              )
            : _c(
                "div",
                {
                  staticClass: "block my-3",
                  class:
                    _vm.$route.path.search(menu.route) > 0
                      ? "text-base text-[#169D23] font-semibold"
                      : "text-gray-800 hover:text-[#169D23] font-medium text-base ",
                  on: {
                    click: function ($event) {
                      return _vm.closePanel()
                    },
                  },
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { path: _vm.localePath(menu.route) } } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t(menu.name)) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
        ])
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm.isLoggedIn
        ? _c(
            "div",
            {
              staticClass:
                "block flex mt-2 font-medium py-2 text-sm text-red-600 hover:bg-red-100 cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.logOut()
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "20",
                    height: "20",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "m14.167 13.334 3.333-3.333m0 0-3.333-3.334m3.333 3.334H5.833m5 3.333v.833a2.5 2.5 0 0 1-2.5 2.5H5a2.5 2.5 0 0 1-2.5-2.5V5.834a2.5 2.5 0 0 1 2.5-2.5h3.333a2.5 2.5 0 0 1 2.5 2.5v.833",
                      stroke: "#DC2626",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "ml-3" }, [
                _vm._v(_vm._s(_vm.$t("logout"))),
              ]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-1 space-y-5 text-gray-700" }, [
      _c("div", { staticClass: "flex items-center gap-2" }, [
        _c("i", { staticClass: "bx bx-envelope lg:text-lg text-base" }),
        _vm._v(" "),
        _c("p", { staticClass: "text-sm" }, [
          _vm._v("juanjose.robalino@gggi.org"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex gap-2" }, [
        _c("i", { staticClass: "bx bx-map lg:text-lg text-base" }),
        _vm._v(" "),
        _c("p", { staticClass: "text-sm" }, [
          _vm._v("7a, Bunyodkor av., 100000, Tashkent, Uzbekistan"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }