<template>
  <div>
    <nav class="static w-full lg:block hidden">
      <div class="sm:px-6 lg:px-8 bg-[#169D23] p-3">
        <div class="max-w-6xl mx-auto px-4 sm:px-0">
          <div class="flex items-center justify-between text-white">
            <div class="flex items-center gap-4">
              <div class="flex items-center gap-2">
                <i class="bx bx-envelope text-white text-lg"></i>
                <p class="text-sm" v-if='items.attributes && items.attributes.email'>{{ $t('email') }}: {{items.attributes.email}}</p>
              </div>
              <!-- <div class="flex items-center gap-2">
                <i class="bx bx-phone text-white text-lg"></i>
                <p class="text-sm" v-if='items.attributes && items.attributes.phone'>{{items.attributes.phone}}</p>
              </div> -->
              <div class="flex items-center gap-2">
                <i class="bx bx-map text-white text-lg"></i>
                <p class="text-sm" v-if='items.attributes && items.attributes.address'>{{items.attributes.address
                  }}</p>
              </div>
            </div>
            <lang-switcher />
          </div>
        </div>
      </div>
      <div class="sm:px-6 lg:px-8 bg-white p-3 shadow-md" :class="{'activeClass': isActive}">
        <div class="max-w-6xl mx-auto px-4 sm:px-0">
          <div class="flex items-center justify-between text-white">
            <router-link :to="localePath('/')">
              <!-- <router-link :to="{ path: localePath('/') }"> -->
              <span class="text-[#169D23] text-4xl font-extrabold">{{ $t('green-finance') }}</span>
            </router-link>
            <div class="flex items-center gap-6">
              <div v-for="(menu, index) in navbar" :key="index" class="flex items-center relative">
                <div v-if="menu.children">
                  <div class="flex items-center cursor-pointer classAvtive">
                    <a
                      :class="
                        menu.current
                          ? 'text-base text-[#169D23]  font-medium flex items-center'
                          : 'text-[#169D23] font-medium text-base flex items-center'
                      "
                      @click="menuWithChildClicked(menu)"
                    >
                      {{ $t(menu.name) }}
                      <i
                        class="text-gray-800 text-xl"
                        :class="[menu.current ? menu.iconUp : menu.iconDown]"
                      />
                    </a>
                  </div>
                  <div class="absolute top-8 z-10">
                    <div
                      class="rounded-md bg-white shadow-md"
                      :class="$i18n.locale === 'ru' ? 'w-44' : 'w-36'">
                      <div
                        v-for="(child, k) in menu.children"
                        v-show="menu.current"
                        :key="k"
                        class="pb-2"
                      >
                        <div
                          v-if="child.route"
                          class="cursor-pointer text-gray-800 px-4 text-sm font-medium rounded-md"
                          @click="menuChildClicked(menu, child)"
                        >
                          <div>{{ $t(child.name) }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <router-link
                  v-else
                  :to="localePath(menu.route)"
                  :class="
                    $route.path.search(menu.route) > 0
                      ? 'text-base text-[#169D23] border-b-2 font-medium border-[#169D23]'
                      : 'text-gray-800 hover:text-[#169D23] font-medium hover:border-b-2 hover:border-[#169D23] text-base '
                  "
                >
                  {{ $t(menu.name) }}
                </router-link>
              </div>
            </div>
            <div v-if="$auth.loggedIn" class="ml-3 relative parent-class">
              <div class="flex items-center justify-end">
                <button
                  id="user-menu-button"
                  type="button"
                  class="justify-end inline-flex items-center py-2 text-sm font-medium focus:outline-none border border-transparent bg-light-gray text-gray-600 lg:rounded-md lg:hover:bg-gray-50"
                  aria-expanded="false"
                  aria-haspopup="true"
                  @click="openProfile"
                >
                  <img
                    class="w-10 h-10 object-cover flex justify-end rounded-full"
                    alt="Avatar"
                    :src="
                      currentUser.avatar
                        ? $tools.getFileUrl(currentUser.avatar)
                        : require('/assets/images/person/avatar.jpg')
                    "
                    @error="currentUser.avatar = require('/assets/images/person/avatar.jpg')"
                  />
                </button>
              </div>
              <div
                class="z-20 origin-top-right absolute right-0 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none child-class"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabindex="-1"
              >
                <div
                  class="block font-medium hover:bg-gray-100 px-4 py-2 text-sm text-gray-600 cursor-pointer"
                >
                  {{
                    `${currentUser.name ? currentUser.name : ''} ${
                      currentUser.surname ? currentUser.surname : ''
                    }`
                  }}
                  <br />
                  <span class="text-xs text-gray-500">ID: {{ currentUser.id }}</span>
                </div>
                <div
                  @click="toMyChats"
                  class="block font-medium px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 cursor-pointer"
                >
                  {{ $t('my-chats') }}
                </div>
                <div
                  class="block font-medium px-4 py-2 text-sm text-red-600 hover:bg-red-100 cursor-pointer"
                  @click="logOut()"
                >
                  {{ $t('logout') }}
                </div>
              </div>
            </div>
            <!-- <nuxt-link
              v-else
              :to="localePath('/login')"
              class="text-white focus:outline-none text-sm rounded-md bg-[#169D23] p-3"
            >
              {{ $t('login-register') }}
            </nuxt-link> -->
          </div>
        </div>
      </div>
    </nav>
    <nav 
      class="lg:hidden flex items-center h-14 justify-between max-w-6xl mx-auto px-4"
      :class="$route.path.includes('about') ? 'responsive-design' : ''"
    >
      <div>
        <button
          type="button"
          class="-mx-2 rounded-md p-2 inline-flex items-center justify-center focus:outline-none"
          aria-expanded="false"
          @click="openMobileMenu"
        >
          <i class="bx bx-menu text-[#169D23] text-3xl" />
        </button>
        <slideout-panel />
      </div>
      <div class="flex-shrink-0 flex items-center">
        <router-link :to="{ path: localePath('/') }">
          <svg width="150" height="30" viewBox="0 0 253 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.1399 29.4002C12.0199 29.4002 9.37991 28.8135 7.21991 27.6402C5.08658 26.4668 3.45991 24.8002 2.33991 22.6402C1.21991 20.4802 0.659912 17.9202 0.659912 14.9602C0.659912 12.7202 0.979912 10.7068 1.61991 8.92015C2.28658 7.13349 3.23325 5.61349 4.45991 4.36015C5.71325 3.08015 7.23325 2.10682 9.01991 1.44016C10.8066 0.746822 12.8332 0.400155 15.0999 0.400155C16.5932 0.400155 18.0866 0.586822 19.5799 0.960155C21.0999 1.30682 22.5266 1.90682 23.8599 2.76016C24.3132 3.05349 24.6066 3.41349 24.7399 3.84016C24.8999 4.26682 24.9266 4.70682 24.8199 5.16016C24.7132 5.58682 24.5132 5.96015 24.2199 6.28016C23.9266 6.60015 23.5532 6.80016 23.0999 6.88016C22.6732 6.96016 22.1932 6.85349 21.6599 6.56015C20.6466 5.97349 19.6199 5.54682 18.5799 5.28016C17.5399 5.01349 16.3799 4.88015 15.0999 4.88015C13.1266 4.88015 11.4599 5.28016 10.0999 6.08016C8.73991 6.85349 7.71325 7.98682 7.01991 9.48016C6.35325 10.9735 6.01991 12.8002 6.01991 14.9602C6.01991 18.2402 6.80658 20.7335 8.37991 22.4402C9.95325 24.1468 12.2866 25.0002 15.3799 25.0002C16.4466 25.0002 17.5266 24.8935 18.6199 24.6802C19.7132 24.4668 20.7799 24.1735 21.8199 23.8002L20.8199 26.0802V17.5602H16.4199C15.7532 17.5602 15.2332 17.3868 14.8599 17.0402C14.5132 16.6935 14.3399 16.2268 14.3399 15.6402C14.3399 15.0268 14.5132 14.5602 14.8599 14.2402C15.2332 13.9202 15.7532 13.7602 16.4199 13.7602H23.0199C23.6866 13.7602 24.1932 13.9468 24.5399 14.3202C24.9132 14.6668 25.0999 15.1735 25.0999 15.8402V25.5602C25.0999 26.1468 24.9799 26.6402 24.7399 27.0402C24.5266 27.4402 24.1666 27.7335 23.6599 27.9202C22.4866 28.3468 21.1399 28.7068 19.6199 29.0002C18.0999 29.2668 16.6066 29.4002 15.1399 29.4002Z" fill="#1565C0"/>
                <path d="M33.113 29.3202C32.2864 29.3202 31.6464 29.1068 31.193 28.6802C30.7664 28.2268 30.553 27.5868 30.553 26.7602V11.6402C30.553 10.8135 30.7664 10.1868 31.193 9.76015C31.6197 9.33349 32.2197 9.12016 32.993 9.12016C33.7664 9.12016 34.3664 9.33349 34.793 9.76015C35.2197 10.1868 35.433 10.8135 35.433 11.6402V14.1602H35.033C35.4064 12.5602 36.1397 11.3468 37.233 10.5202C38.3264 9.69349 39.7797 9.20016 41.593 9.04016C42.153 8.98682 42.593 9.13349 42.913 9.48016C43.2597 9.80016 43.4597 10.3068 43.513 11.0002C43.5664 11.6668 43.4064 12.2135 43.033 12.6402C42.6864 13.0402 42.153 13.2802 41.433 13.3602L40.553 13.4402C38.9264 13.6002 37.6997 14.1068 36.873 14.9602C36.0464 15.7868 35.633 16.9602 35.633 18.4802V26.7602C35.633 27.5868 35.4197 28.2268 34.993 28.6802C34.5664 29.1068 33.9397 29.3202 33.113 29.3202Z" fill="#1565C0"/>
                <path d="M55.5446 29.4002C53.3313 29.4002 51.4246 28.9868 49.8246 28.1602C48.2246 27.3335 46.9846 26.1602 46.1046 24.6402C45.2513 23.1202 44.8246 21.3202 44.8246 19.2402C44.8246 17.2135 45.2379 15.4402 46.0646 13.9202C46.9179 12.4002 48.0779 11.2135 49.5446 10.3602C51.0379 9.48015 52.7313 9.04016 54.6246 9.04016C56.0113 9.04016 57.2513 9.26682 58.3446 9.72016C59.4646 10.1735 60.4113 10.8268 61.1846 11.6802C61.9846 12.5335 62.5846 13.5735 62.9846 14.8002C63.4113 16.0002 63.6246 17.3602 63.6246 18.8802C63.6246 19.3602 63.4513 19.7335 63.1046 20.0002C62.7846 20.2402 62.3179 20.3602 61.7046 20.3602H48.8646V17.4802H60.1046L59.4646 18.0802C59.4646 16.8535 59.2779 15.8268 58.9046 15.0002C58.5579 14.1735 58.0379 13.5468 57.3446 13.1202C56.6779 12.6668 55.8379 12.4402 54.8246 12.4402C53.7046 12.4402 52.7446 12.7068 51.9446 13.2402C51.1713 13.7468 50.5713 14.4802 50.1446 15.4402C49.7446 16.3735 49.5446 17.4935 49.5446 18.8002V19.0802C49.5446 21.2668 50.0513 22.9068 51.0646 24.0002C52.1046 25.0668 53.6246 25.6002 55.6246 25.6002C56.3179 25.6002 57.0913 25.5202 57.9446 25.3602C58.8246 25.1735 59.6513 24.8668 60.4246 24.4402C60.9846 24.1202 61.4779 23.9868 61.9046 24.0402C62.3313 24.0668 62.6646 24.2135 62.9046 24.4802C63.1713 24.7468 63.3313 25.0802 63.3846 25.4802C63.4379 25.8535 63.3579 26.2402 63.1446 26.6402C62.9579 27.0402 62.6246 27.3868 62.1446 27.6802C61.2113 28.2668 60.1313 28.7068 58.9046 29.0002C57.7046 29.2668 56.5846 29.4002 55.5446 29.4002Z" fill="#1565C0"/>
                <path d="M77.2243 29.4002C75.0109 29.4002 73.1043 28.9868 71.5043 28.1602C69.9043 27.3335 68.6643 26.1602 67.7843 24.6402C66.931 23.1202 66.5043 21.3202 66.5043 19.2402C66.5043 17.2135 66.9176 15.4402 67.7443 13.9202C68.5976 12.4002 69.7576 11.2135 71.2243 10.3602C72.7176 9.48015 74.4109 9.04016 76.3043 9.04016C77.6909 9.04016 78.931 9.26682 80.0243 9.72016C81.1443 10.1735 82.091 10.8268 82.8643 11.6802C83.6643 12.5335 84.2643 13.5735 84.6643 14.8002C85.091 16.0002 85.3043 17.3602 85.3043 18.8802C85.3043 19.3602 85.131 19.7335 84.7843 20.0002C84.4643 20.2402 83.9976 20.3602 83.3843 20.3602H70.5443V17.4802H81.7843L81.1443 18.0802C81.1443 16.8535 80.9576 15.8268 80.5843 15.0002C80.2376 14.1735 79.7176 13.5468 79.0243 13.1202C78.3576 12.6668 77.5176 12.4402 76.5043 12.4402C75.3843 12.4402 74.4243 12.7068 73.6243 13.2402C72.851 13.7468 72.2509 14.4802 71.8243 15.4402C71.4243 16.3735 71.2243 17.4935 71.2243 18.8002V19.0802C71.2243 21.2668 71.7309 22.9068 72.7443 24.0002C73.7843 25.0668 75.3043 25.6002 77.3043 25.6002C77.9976 25.6002 78.771 25.5202 79.6243 25.3602C80.5043 25.1735 81.331 24.8668 82.1043 24.4402C82.6643 24.1202 83.1576 23.9868 83.5843 24.0402C84.0109 24.0668 84.3443 24.2135 84.5843 24.4802C84.851 24.7468 85.0109 25.0802 85.0643 25.4802C85.1176 25.8535 85.0376 26.2402 84.8243 26.6402C84.6376 27.0402 84.3043 27.3868 83.8243 27.6802C82.891 28.2668 81.811 28.7068 80.5843 29.0002C79.3843 29.2668 78.2643 29.4002 77.2243 29.4002Z" fill="#1565C0"/>
                <path d="M91.744 29.3202C90.944 29.3202 90.3306 29.1068 89.904 28.6802C89.4773 28.2268 89.264 27.5868 89.264 26.7602V11.6402C89.264 10.8135 89.4773 10.1868 89.904 9.76015C90.3306 9.33349 90.9306 9.12016 91.704 9.12016C92.4773 9.12016 93.0773 9.33349 93.504 9.76015C93.9306 10.1868 94.144 10.8135 94.144 11.6402V14.3602L93.704 13.3602C94.2906 11.9468 95.1973 10.8802 96.424 10.1602C97.6773 9.41349 99.0906 9.04016 100.664 9.04016C102.237 9.04016 103.531 9.33349 104.544 9.92015C105.557 10.5068 106.317 11.4002 106.824 12.6002C107.331 13.7735 107.584 15.2668 107.584 17.0802V26.7602C107.584 27.5868 107.371 28.2268 106.944 28.6802C106.517 29.1068 105.904 29.3202 105.104 29.3202C104.304 29.3202 103.677 29.1068 103.224 28.6802C102.797 28.2268 102.584 27.5868 102.584 26.7602V17.3202C102.584 15.8002 102.291 14.6935 101.704 14.0002C101.144 13.3068 100.264 12.9602 99.064 12.9602C97.5973 12.9602 96.424 13.4268 95.544 14.3602C94.6906 15.2668 94.264 16.4802 94.264 18.0002V26.7602C94.264 28.4668 93.424 29.3202 91.744 29.3202Z" fill="#1565C0"/>
                <path d="M127.362 29.3202C126.562 29.3202 125.935 29.1068 125.482 28.6802C125.055 28.2268 124.842 27.5868 124.842 26.7602V13.2002H123.002C122.362 13.2002 121.868 13.0402 121.522 12.7202C121.175 12.3735 121.002 11.9068 121.002 11.3202C121.002 10.7068 121.175 10.2402 121.522 9.92015C121.868 9.60015 122.362 9.44016 123.002 9.44016H126.282L124.842 10.7602V9.40016C124.842 6.70682 125.522 4.66682 126.882 3.28016C128.242 1.89349 130.215 1.06682 132.802 0.800155L134.002 0.680157C134.615 0.626822 135.095 0.706822 135.442 0.920156C135.815 1.13349 136.055 1.42682 136.162 1.80016C136.295 2.14682 136.322 2.52016 136.242 2.92016C136.162 3.29349 136.002 3.62682 135.762 3.92015C135.522 4.18682 135.215 4.33349 134.842 4.36015L133.882 4.44016C132.468 4.54682 131.442 4.93349 130.802 5.60015C130.162 6.26682 129.842 7.26682 129.842 8.60015V10.0802L129.202 9.44016H140.282C141.082 9.44016 141.695 9.68016 142.122 10.1602C142.548 10.6135 142.762 11.2668 142.762 12.1202V26.5602C142.762 27.4135 142.548 28.0802 142.122 28.5602C141.722 29.0402 141.108 29.2802 140.282 29.2802C139.482 29.2802 138.855 29.0402 138.402 28.5602C137.975 28.0802 137.762 27.4135 137.762 26.5602V13.2002H129.842V26.7602C129.842 28.4668 129.015 29.3202 127.362 29.3202ZM140.362 5.44016C139.455 5.44016 138.748 5.21349 138.242 4.76016C137.762 4.28016 137.522 3.62682 137.522 2.80016C137.522 1.94682 137.762 1.29349 138.242 0.840155C138.748 0.386822 139.455 0.160156 140.362 0.160156C141.295 0.160156 142.002 0.386822 142.482 0.840155C142.988 1.29349 143.242 1.94682 143.242 2.80016C143.242 3.62682 142.988 4.28016 142.482 4.76016C142.002 5.21349 141.295 5.44016 140.362 5.44016Z" fill="#1565C0"/>
                <path d="M150.455 29.3202C149.655 29.3202 149.042 29.1068 148.615 28.6802C148.188 28.2268 147.975 27.5868 147.975 26.7602V11.6402C147.975 10.8135 148.188 10.1868 148.615 9.76015C149.042 9.33349 149.642 9.12016 150.415 9.12016C151.188 9.12016 151.788 9.33349 152.215 9.76015C152.642 10.1868 152.855 10.8135 152.855 11.6402V14.3602L152.415 13.3602C153.002 11.9468 153.908 10.8802 155.135 10.1602C156.388 9.41349 157.802 9.04016 159.375 9.04016C160.948 9.04016 162.242 9.33349 163.255 9.92015C164.268 10.5068 165.028 11.4002 165.535 12.6002C166.042 13.7735 166.295 15.2668 166.295 17.0802V26.7602C166.295 27.5868 166.082 28.2268 165.655 28.6802C165.228 29.1068 164.615 29.3202 163.815 29.3202C163.015 29.3202 162.388 29.1068 161.935 28.6802C161.508 28.2268 161.295 27.5868 161.295 26.7602V17.3202C161.295 15.8002 161.002 14.6935 160.415 14.0002C159.855 13.3068 158.975 12.9602 157.775 12.9602C156.308 12.9602 155.135 13.4268 154.255 14.3602C153.402 15.2668 152.975 16.4802 152.975 18.0002V26.7602C152.975 28.4668 152.135 29.3202 150.455 29.3202Z" fill="#1565C0"/>
                <path d="M177.813 29.4002C176.4 29.4002 175.133 29.1335 174.013 28.6002C172.92 28.0402 172.053 27.2935 171.413 26.3602C170.8 25.4268 170.493 24.3735 170.493 23.2002C170.493 21.7602 170.867 20.6268 171.613 19.8002C172.36 18.9468 173.573 18.3335 175.253 17.9602C176.933 17.5868 179.187 17.4002 182.013 17.4002H184.013V20.2802H182.053C180.4 20.2802 179.08 20.3602 178.093 20.5202C177.107 20.6802 176.4 20.9602 175.973 21.3602C175.573 21.7335 175.373 22.2668 175.373 22.9602C175.373 23.8402 175.68 24.5602 176.293 25.1202C176.907 25.6802 177.76 25.9602 178.853 25.9602C179.733 25.9602 180.507 25.7602 181.173 25.3602C181.867 24.9335 182.413 24.3602 182.813 23.6402C183.213 22.9202 183.413 22.0935 183.413 21.1602V16.5602C183.413 15.2268 183.12 14.2668 182.533 13.6802C181.947 13.0935 180.96 12.8002 179.573 12.8002C178.8 12.8002 177.96 12.8935 177.053 13.0802C176.173 13.2668 175.24 13.5868 174.253 14.0402C173.747 14.2802 173.293 14.3468 172.893 14.2402C172.52 14.1335 172.227 13.9202 172.013 13.6002C171.8 13.2535 171.693 12.8802 171.693 12.4802C171.693 12.0802 171.8 11.6935 172.013 11.3202C172.227 10.9202 172.587 10.6268 173.093 10.4402C174.32 9.93349 175.493 9.57349 176.613 9.36016C177.76 9.14682 178.8 9.04016 179.733 9.04016C181.653 9.04016 183.227 9.33349 184.453 9.92015C185.707 10.5068 186.64 11.4002 187.253 12.6002C187.867 13.7735 188.173 15.2935 188.173 17.1602V26.7602C188.173 27.5868 187.973 28.2268 187.573 28.6802C187.173 29.1068 186.6 29.3202 185.853 29.3202C185.107 29.3202 184.52 29.1068 184.093 28.6802C183.693 28.2268 183.493 27.5868 183.493 26.7602V24.8402H183.813C183.627 25.7735 183.253 26.5868 182.693 27.2802C182.16 27.9468 181.48 28.4668 180.653 28.8402C179.827 29.2135 178.88 29.4002 177.813 29.4002Z" fill="#1565C0"/>
                <path d="M195.728 29.3202C194.928 29.3202 194.315 29.1068 193.888 28.6802C193.462 28.2268 193.248 27.5868 193.248 26.7602V11.6402C193.248 10.8135 193.462 10.1868 193.888 9.76015C194.315 9.33349 194.915 9.12016 195.688 9.12016C196.462 9.12016 197.062 9.33349 197.488 9.76015C197.915 10.1868 198.128 10.8135 198.128 11.6402V14.3602L197.688 13.3602C198.275 11.9468 199.182 10.8802 200.408 10.1602C201.662 9.41349 203.075 9.04016 204.648 9.04016C206.222 9.04016 207.515 9.33349 208.528 9.92015C209.542 10.5068 210.302 11.4002 210.808 12.6002C211.315 13.7735 211.568 15.2668 211.568 17.0802V26.7602C211.568 27.5868 211.355 28.2268 210.928 28.6802C210.502 29.1068 209.888 29.3202 209.088 29.3202C208.288 29.3202 207.662 29.1068 207.208 28.6802C206.782 28.2268 206.568 27.5868 206.568 26.7602V17.3202C206.568 15.8002 206.275 14.6935 205.688 14.0002C205.128 13.3068 204.248 12.9602 203.048 12.9602C201.582 12.9602 200.408 13.4268 199.528 14.3602C198.675 15.2668 198.248 16.4802 198.248 18.0002V26.7602C198.248 28.4668 197.408 29.3202 195.728 29.3202Z" fill="#1565C0"/>
                <path d="M225.687 29.4002C223.633 29.4002 221.847 28.9868 220.327 28.1602C218.807 27.3068 217.633 26.1068 216.807 24.5602C215.98 23.0135 215.567 21.2002 215.567 19.1202C215.567 17.5468 215.793 16.1468 216.247 14.9202C216.727 13.6668 217.407 12.6135 218.287 11.7602C219.167 10.8802 220.233 10.2135 221.487 9.76015C222.74 9.28016 224.14 9.04016 225.687 9.04016C226.567 9.04016 227.513 9.16016 228.527 9.40016C229.567 9.64016 230.54 10.0402 231.447 10.6002C231.873 10.8668 232.153 11.1868 232.287 11.5602C232.42 11.9335 232.447 12.3202 232.367 12.7202C232.287 13.0935 232.113 13.4268 231.847 13.7202C231.607 13.9868 231.3 14.1602 230.927 14.2402C230.553 14.2935 230.14 14.2002 229.687 13.9602C229.1 13.6135 228.5 13.3602 227.887 13.2002C227.273 13.0135 226.687 12.9202 226.127 12.9202C225.247 12.9202 224.473 13.0668 223.807 13.3602C223.14 13.6268 222.567 14.0268 222.087 14.5602C221.633 15.0668 221.287 15.7068 221.047 16.4802C220.807 17.2535 220.687 18.1468 220.687 19.1602C220.687 21.1335 221.153 22.6935 222.087 23.8402C223.047 24.9602 224.393 25.5202 226.127 25.5202C226.687 25.5202 227.26 25.4402 227.847 25.2802C228.46 25.1202 229.073 24.8668 229.687 24.5202C230.14 24.2802 230.54 24.2002 230.887 24.2802C231.26 24.3602 231.567 24.5468 231.807 24.8402C232.047 25.1068 232.193 25.4402 232.247 25.8402C232.3 26.2135 232.247 26.5868 232.087 26.9602C231.953 27.3335 231.687 27.6402 231.287 27.8802C230.407 28.4135 229.473 28.8002 228.487 29.0402C227.5 29.2802 226.567 29.4002 225.687 29.4002Z" fill="#1565C0"/>
                <path d="M244.763 29.4002C242.55 29.4002 240.643 28.9868 239.043 28.1602C237.443 27.3335 236.203 26.1602 235.323 24.6402C234.47 23.1202 234.043 21.3202 234.043 19.2402C234.043 17.2135 234.457 15.4402 235.283 13.9202C236.137 12.4002 237.297 11.2135 238.763 10.3602C240.257 9.48015 241.95 9.04016 243.843 9.04016C245.23 9.04016 246.47 9.26682 247.563 9.72016C248.683 10.1735 249.63 10.8268 250.403 11.6802C251.203 12.5335 251.803 13.5735 252.203 14.8002C252.63 16.0002 252.843 17.3602 252.843 18.8802C252.843 19.3602 252.67 19.7335 252.323 20.0002C252.003 20.2402 251.537 20.3602 250.923 20.3602H238.083V17.4802H249.323L248.683 18.0802C248.683 16.8535 248.497 15.8268 248.123 15.0002C247.777 14.1735 247.257 13.5468 246.563 13.1202C245.897 12.6668 245.057 12.4402 244.043 12.4402C242.923 12.4402 241.963 12.7068 241.163 13.2402C240.39 13.7468 239.79 14.4802 239.363 15.4402C238.963 16.3735 238.763 17.4935 238.763 18.8002V19.0802C238.763 21.2668 239.27 22.9068 240.283 24.0002C241.323 25.0668 242.843 25.6002 244.843 25.6002C245.537 25.6002 246.31 25.5202 247.163 25.3602C248.043 25.1735 248.87 24.8668 249.643 24.4402C250.203 24.1202 250.697 23.9868 251.123 24.0402C251.55 24.0668 251.883 24.2135 252.123 24.4802C252.39 24.7468 252.55 25.0802 252.603 25.4802C252.657 25.8535 252.577 26.2402 252.363 26.6402C252.177 27.0402 251.843 27.3868 251.363 27.6802C250.43 28.2668 249.35 28.7068 248.123 29.0002C246.923 29.2668 245.803 29.4002 244.763 29.4002Z" fill="#1565C0"/>
              </svg>
        </router-link>
      </div>
      <lang-switcher />
    </nav>
  </div>
</template>

<script>
import LangSwitcher from '../core/lang-switcher.vue'
import mobileMenu from '../core/mobile-menu.vue'
import { mapState } from 'vuex'
import { socket } from '~/plugins/socket.client.js'
export default {
  name: 'Navbar',
  props: ['items'],
  components: {
    LangSwitcher,
  },
  data() {
    return {
      isProfileOpened: false,
      isActive: false,
      navbar: [
        {
          id: 0,
          name: 'home',
          route: '/',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        {
          id: 1,
          name: 'about',
          route: '/about',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        {
          id: 2,
          name: 'training',
          route: '/training',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        // {
        //   id: 3,
        //   name: 'agri-business',
        //   route: '/agri-business',
        //   current: false,
        //   children: [
        //     { name: 'agro-location', route: '/agri-business', current: false },
        //     { name: 'agro-tools', route: '/agro-tools', current: false },
        //   ],
        //   iconDown: 'bx bx-chevron-down',
        //   iconUp: 'bx bx-chevron-up',
        // },
        {
          id: 4,
          name: 'agri-finance',
          route: '/agri-finance',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        // {
        //   id: 5,
        //   name: 'agri-market',
        //   iconDown: 'bx bx-chevron-down',
        //   iconUp: 'bx bx-chevron-up',
        //   route: '/agri-market',
        //   current: false,
        // },
        // {
        //   id: 6,
        //   name: 'advisory',
        //   route: '/advisory',
        //   current: false,
        //   iconDown: 'bx bx-chevron-down',
        //   iconUp: 'bx bx-chevron-up',
        // },
      ],
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.loggedIn,
      currentUser: (state) => state.auth.user,
    }),
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 400) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    },
    async logOut() {
      await socket.emit('leave', {
        username: this.currentUser.username,
        user_id: this.currentUser.id,
      })
      this.isProfileOpened = false
      await localStorage.removeItem('local')
      await localStorage.removeItem('user_info')
      await this.$auth.logout()
    },
    toMyChats() {
      this.isProfileOpened = false
      this.$router.push({ path: this.localePath('/chats') })
    },
    openProfile() {
      this.isProfileOpened = !this.isProfileOpened
    },
    openMobileMenu() {
      this.$showPanel({
        component: mobileMenu,
        openOn: 'left',
        width: (window.innerWidth * 3) / 4,
      })
    },
    menuChildClicked(menu, child) {
      this.navbar.forEach(el=> {
        if(el.name === menu.name) {
          el.current = false
        }

      })
      menu.children.forEach((ch) => {
        if (child === ch) {
          ch.current = true
        } else {
          ch.current = false
        }
      })
      this.$router.push(this.localePath(child.route))
      return child
    },
    menuWithChildClicked(menu) {
      this.navbar.forEach((navbar) => {
        if (menu === navbar) {
          navbar.current = !navbar.current
          navbar.children.forEach((child) => {
            child.current = false
          })
        } else {
          navbar.current = false
        }
      })
      return menu
    },
    signIn() {
      this.$router.push({
        path: this.localePath('/login'),
      })
    },
  },
}
</script>
<style scoped>
.activeClass {
  position: fixed;
  top: 0;
  z-index: 10000;
  width: 100%;
}
.classAvtive {
  position: relative;
}
.notAvtive {
  display: none;
}
.child-class {
  display: none;
}
.parent-class:hover .child-class {
  display: block;
}
.responsive-design {
  min-width: 650px;
  width: 100%;
}
</style>
