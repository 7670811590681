var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "relative z-10",
      attrs: {
        "aria-labelledby": "modal-title",
        role: "dialog",
        "aria-modal": "true",
      },
    },
    [
      _c("div", {
        staticClass:
          "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",
      }),
      _vm._v(" "),
      _c("div", { staticClass: "fixed z-10 inset-0 overflow-y-auto" }, [
        _c(
          "div",
          {
            staticClass:
              "flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6",
              },
              [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end",
                        cursor: "pointer",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onClose()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "35",
                          height: "35",
                          src: require("assets/images/close.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100",
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "h-6 w-6 text-green-600",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            fill: "none",
                            viewBox: "0 0 24 24",
                            "stroke-width": "2",
                            stroke: "currentColor",
                            "aria-hidden": "true",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              d: "M5 13l4 4L19 7",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3 text-center sm:mt-5" }, [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "text-lg leading-6 font-medium text-gray-900",
                        attrs: { id: "modal-title" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("information-submitted")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-2" }, [
                      _c("p", { staticClass: "text-sm text-gray-500" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$t("consultant-warning-info")) +
                            "\n                "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }