<template>
  <div>
    <div v-if="!custom">
      <div class="lg:flex block items-center justify-between">
        <div class="font-semibold text-black text-2xl">
          <span class="text-black">{{ name }}</span>
        </div>
        <div v-if="!isConsultant" class="mt-1 flex rounded-md shadow-sm">
          <div
            class="relative py-0.5 bg-[#169D23] rounded-md rounded-r-none flex items-stretch flex-grow focus-within:z-10 lg:my-0 mt-4"
          >
            <input
              v-model="filter.text"
              id="search"
              type="search"
              name="search"
              class="block w-full p-2 ml-0.5 focus:outline-none sm:text-sm border-gray-300 border rounded-md"
              placeholder="Search"
            />
          </div>
          <button
            type="button"
            class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 lg:my-0 mt-4 bg-[#169D23] text-sm font-medium rounded-r-md focus:outline-none"
          >
            <i class="bx bx-search text-lg text-white"></i>
          </button>
        </div>
        <div v-else class="border lg:block rounded-md border-[#169D23] text-[#169D23] p-2 hidden">
          <select
            class="font-semibold focus:outline-none flex justify-between w-32"
            v-model="filter.category"
            @change="onChangeCategory"
          >
            <option
              v-for="(category, index) in categories"
              :key="index"
              :value="category.id"
              class="pr-4"
            >
              {{ category.attributes.name }}
            </option>
            <i class="bx bx-chevron-down text-lg"></i>
          </select>
        </div>
      </div>
      <div
        v-if="!isConsultant"
        class="lg:flex grid-cols-3 items-center hidden justify-center lg:gap-7 gap-4 my-6"
      >
        <button
          v-for="(category, index) in categories"
          :key="index"
          class="rounded-md flex items-center focus:outline-none py-1.5 px-4"
          :class="
            $route.query.category && parseInt($route.query.category) === category.id
              ? 'bg-[#169D23] text-white'
              : 'border border-[#169D23] bg-[#169D23] hover:text-white hover:bg-[#169D23]'
          "
          @click="toChange(category)"
        >
          {{ category.attributes ? category.attributes.name : '' }}
        </button>
      </div>
      <div class="lg:hidden flex mt-4">
        <swiper
          ref="swiper"
          class="swiper"
          :options="categoriesOption"
          :autoplay="{ delay: 2000, disableOnIteraction: false }"
          :pagination="{ clickable: true }"
        >
          <client-only>
          <swiper-slide v-for="(category, index) in categories" :key="index">
            <button
              class="rounded-md py-1.5 mb-4 w-full"
              :class="
              $route.query.category && parseInt($route.query.category) === category.id
                ? 'bg-[#169D23] text-white'
                : 'border border-[#169D23] text-[#169D23] hover:text-white hover:bg[#169D23]'
            "
              @click="toChange(category)"
            >
              {{ category.attributes ? category.attributes.name : '' }}
            </button>
          </swiper-slide>
          </client-only>
        </swiper>
      </div>
    </div>
    <div 
      v-else 
      class="flex flex-col rounded-2xl overflow-hidden sm:h-auto bg-[rgb(250,250,250)]"
      :class="dropdownOpen ? 'h-auto' : 'h-[70px]'"
    >
      <div 
        class="sm:hidden border-b border-[#D7D0D0] py-6 px-8 cursor-pointer text-[#00730C] font-bold flex justify-between items-center"
        @click="dropdownOpen = !dropdownOpen"
      >
        <span>{{ categories.find(res => res.id == $route.query.category)?.attributes?.name }}</span>
        <svg :class="dropdownOpen ? 'rotate-180 transition-all' : ''" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.99997 7.42017C5.7849 7.42017 5.56987 7.33806 5.4059 7.17417L0.24617 2.01439C-0.0820565 1.68616 -0.0820565 1.154 0.24617 0.825904C0.574263 0.497811 1.10632 0.497811 1.43457 0.825904L5.99997 5.39156L10.5654 0.826063C10.8936 0.49797 11.4256 0.49797 11.7537 0.826063C12.0821 1.15416 12.0821 1.68632 11.7537 2.01455L6.59404 7.17433C6.42999 7.33824 6.21495 7.42017 5.99997 7.42017Z" fill="#000"/>
        </svg>

      </div>
      <div 
        v-for="(category, index) in categories" 
        :key="index"
        @click="toChange(category)"
        class="border-b border-[#D7D0D0] py-6 px-8 cursor-pointer hover:text-[#00730C] font-bold"
        :class="$route.query.category && parseInt($route.query.category) === category.id ? 'sm:text-[#00730C]' : 'text-[#0B0B0B]'"
      >
        {{ category.attributes ? category.attributes.name : '' }}
      </div>
    </div>
  </div>
</template>
<script>
import debounce from 'lodash.debounce'
export default {
  props: {
    categories: Array,
    name: String,
    isConsultant: Boolean,
    custom: Boolean
  },
  directives: {
    debounce,
  },
  data() {
    return {
      dropdownOpen: false,
      filter: {
        category: 0,
        text: '',
      },
      categoriesOption: {
        direction: 'horizontal',
        slideToClickedSlide: false,
        pagination: {
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 6,
          },
          410: {
            slidesPerView: 2.7,
            spaceBetween: 10,
          },
          350: {
            slidesPerView: 2.5,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 2.5,
            spaceBetween: 10,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  mounted() {
    if (this.$route.query.text) {
      this.filter.text = this.$route.query.text
    }
    if (!this.$route.query.category) {
      this.setQuery()
    }
  },
  watch: {
    'filter.text': {
      handler: debounce(function (e) {
        this.setQuery()
      }, 500),
      deep: true,
    },
  },
  methods: {
    onChangeCategory() {
      this.setQuery()
    },
    toChange(category) {
      this.dropdownOpen = false
      this.$ga.event({
      eventCategory: this.$route.path,
      eventAction: 'click',
        eventLabel: category.attributes.name,
        eventValue: category.id
      })
      this.filter.category = category.id
      this.setQuery()
    },
    setQuery() {
      let _query = {
        category: this.filter.category,
        text: this.filter.text.length > 0 ? this.filter.text : null,
      }
      this.$router.push({
        path: this.$route.path,
        query: this.$tools.emptyObject(_query),
      })
    },
  },
}
</script>
