import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4ff1a5db = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _dfb9c7c4 = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "pages/index/index" */))
const _706b4159 = () => interopDefault(import('../pages/index/about.vue' /* webpackChunkName: "pages/index/about" */))
const _35e1809d = () => interopDefault(import('../pages/index/advisory.vue' /* webpackChunkName: "pages/index/advisory" */))
const _2a0322fc = () => interopDefault(import('../pages/index/agri-business.vue' /* webpackChunkName: "pages/index/agri-business" */))
const _16107c7f = () => interopDefault(import('../pages/index/agri-business/index.vue' /* webpackChunkName: "pages/index/agri-business/index" */))
const _ea04375e = () => interopDefault(import('../pages/index/agri-finance/index.vue' /* webpackChunkName: "pages/index/agri-finance/index" */))
const _47683278 = () => interopDefault(import('../pages/index/agri-market.vue' /* webpackChunkName: "pages/index/agri-market" */))
const _3668e7d0 = () => interopDefault(import('../pages/index/agro-tools/index.vue' /* webpackChunkName: "pages/index/agro-tools/index" */))
const _78367c2a = () => interopDefault(import('../pages/index/chats/index.vue' /* webpackChunkName: "pages/index/chats/index" */))
const _50696541 = () => interopDefault(import('../pages/index/chats-old.vue' /* webpackChunkName: "pages/index/chats-old" */))
const _238600bf = () => interopDefault(import('../pages/index/consultant-enterprise.vue' /* webpackChunkName: "pages/index/consultant-enterprise" */))
const _163bd812 = () => interopDefault(import('../pages/index/consultant-individual.vue' /* webpackChunkName: "pages/index/consultant-individual" */))
const _328a7aae = () => interopDefault(import('../pages/index/content-provider.vue' /* webpackChunkName: "pages/index/content-provider" */))
const _7a50169a = () => interopDefault(import('../pages/index/graph.vue' /* webpackChunkName: "pages/index/graph" */))
const _59cabf35 = () => interopDefault(import('../pages/index/login.vue' /* webpackChunkName: "pages/index/login" */))
const _521d5b5a = () => interopDefault(import('../pages/index/marketplace/index.vue' /* webpackChunkName: "pages/index/marketplace/index" */))
const _2ffc9bb7 = () => interopDefault(import('../pages/index/news.vue' /* webpackChunkName: "pages/index/news" */))
const _e585860c = () => interopDefault(import('../pages/index/news/index.vue' /* webpackChunkName: "pages/index/news/index" */))
const _24dc4698 = () => interopDefault(import('../pages/index/news/_detail.vue' /* webpackChunkName: "pages/index/news/_detail" */))
const _219bdcf2 = () => interopDefault(import('../pages/index/register.vue' /* webpackChunkName: "pages/index/register" */))
const _30cc3670 = () => interopDefault(import('../pages/index/register-type.vue' /* webpackChunkName: "pages/index/register-type" */))
const _e5688f86 = () => interopDefault(import('../pages/index/reset-password.vue' /* webpackChunkName: "pages/index/reset-password" */))
const _13a4d841 = () => interopDefault(import('../pages/index/training/index.vue' /* webpackChunkName: "pages/index/training/index" */))
const _5913ef22 = () => interopDefault(import('../pages/index/user-enterprise.vue' /* webpackChunkName: "pages/index/user-enterprise" */))
const _b65bc8b2 = () => interopDefault(import('../pages/index/user-individual.vue' /* webpackChunkName: "pages/index/user-individual" */))
const _0b532bda = () => interopDefault(import('../pages/index/training/detail_old.vue' /* webpackChunkName: "pages/index/training/detail_old" */))
const _1385ee4e = () => interopDefault(import('../pages/index/training/lesson.vue' /* webpackChunkName: "pages/index/training/lesson" */))
const _0ac0911e = () => interopDefault(import('../pages/index/training/multiple.vue' /* webpackChunkName: "pages/index/training/multiple" */))
const _95df17a2 = () => interopDefault(import('../pages/index/agri-finance/_detail.vue' /* webpackChunkName: "pages/index/agri-finance/_detail" */))
const _322e4cf8 = () => interopDefault(import('../pages/index/marketplace/_detail.vue' /* webpackChunkName: "pages/index/marketplace/_detail" */))
const _a06ba7c2 = () => interopDefault(import('../pages/index/training/_detail.vue' /* webpackChunkName: "pages/index/training/_detail" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _4ff1a5db,
    children: [{
      path: "",
      component: _dfb9c7c4,
      name: "index___en"
    }, {
      path: "about",
      component: _706b4159,
      name: "index-about___en"
    }, {
      path: "advisory",
      component: _35e1809d,
      name: "index-advisory___en"
    }, {
      path: "agri-business",
      component: _2a0322fc,
      children: [{
        path: "",
        component: _16107c7f,
        name: "index-agri-business___en"
      }]
    }, {
      path: "agri-finance",
      component: _ea04375e,
      name: "index-agri-finance___en"
    }, {
      path: "agri-market",
      component: _47683278,
      name: "index-agri-market___en"
    }, {
      path: "agro-tools",
      component: _3668e7d0,
      name: "index-agro-tools___en"
    }, {
      path: "chats",
      component: _78367c2a,
      name: "index-chats___en"
    }, {
      path: "chats-old",
      component: _50696541,
      name: "index-chats-old___en"
    }, {
      path: "consultant-enterprise",
      component: _238600bf,
      name: "index-consultant-enterprise___en"
    }, {
      path: "consultant-individual",
      component: _163bd812,
      name: "index-consultant-individual___en"
    }, {
      path: "content-provider",
      component: _328a7aae,
      name: "index-content-provider___en"
    }, {
      path: "graph",
      component: _7a50169a,
      name: "index-graph___en"
    }, {
      path: "login",
      component: _59cabf35,
      name: "index-login___en"
    }, {
      path: "marketplace",
      component: _521d5b5a,
      name: "index-marketplace___en"
    }, {
      path: "news",
      component: _2ffc9bb7,
      children: [{
        path: "",
        component: _e585860c,
        name: "index-news___en"
      }, {
        path: ":detail",
        component: _24dc4698,
        name: "index-news-detail___en"
      }]
    }, {
      path: "register",
      component: _219bdcf2,
      name: "index-register___en"
    }, {
      path: "register-type",
      component: _30cc3670,
      name: "index-register-type___en"
    }, {
      path: "reset-password",
      component: _e5688f86,
      name: "index-reset-password___en"
    }, {
      path: "training",
      component: _13a4d841,
      name: "index-training___en"
    }, {
      path: "user-enterprise",
      component: _5913ef22,
      name: "index-user-enterprise___en"
    }, {
      path: "user-individual",
      component: _b65bc8b2,
      name: "index-user-individual___en"
    }, {
      path: "training/detail_old",
      component: _0b532bda,
      name: "index-training-detail_old___en"
    }, {
      path: "training/lesson",
      component: _1385ee4e,
      name: "index-training-lesson___en"
    }, {
      path: "training/multiple",
      component: _0ac0911e,
      name: "index-training-multiple___en"
    }, {
      path: "agri-finance/:detail?",
      component: _95df17a2,
      name: "index-agri-finance-detail___en"
    }, {
      path: "marketplace/:detail",
      component: _322e4cf8,
      name: "index-marketplace-detail___en"
    }, {
      path: "training/:detail",
      component: _a06ba7c2,
      name: "index-training-detail___en"
    }]
  }, {
    path: "/ru",
    component: _4ff1a5db,
    children: [{
      path: "",
      component: _dfb9c7c4,
      name: "index___ru"
    }, {
      path: "about",
      component: _706b4159,
      name: "index-about___ru"
    }, {
      path: "advisory",
      component: _35e1809d,
      name: "index-advisory___ru"
    }, {
      path: "agri-business",
      component: _2a0322fc,
      children: [{
        path: "",
        component: _16107c7f,
        name: "index-agri-business___ru"
      }]
    }, {
      path: "agri-finance",
      component: _ea04375e,
      name: "index-agri-finance___ru"
    }, {
      path: "agri-market",
      component: _47683278,
      name: "index-agri-market___ru"
    }, {
      path: "agro-tools",
      component: _3668e7d0,
      name: "index-agro-tools___ru"
    }, {
      path: "chats",
      component: _78367c2a,
      name: "index-chats___ru"
    }, {
      path: "chats-old",
      component: _50696541,
      name: "index-chats-old___ru"
    }, {
      path: "consultant-enterprise",
      component: _238600bf,
      name: "index-consultant-enterprise___ru"
    }, {
      path: "consultant-individual",
      component: _163bd812,
      name: "index-consultant-individual___ru"
    }, {
      path: "content-provider",
      component: _328a7aae,
      name: "index-content-provider___ru"
    }, {
      path: "graph",
      component: _7a50169a,
      name: "index-graph___ru"
    }, {
      path: "login",
      component: _59cabf35,
      name: "index-login___ru"
    }, {
      path: "marketplace",
      component: _521d5b5a,
      name: "index-marketplace___ru"
    }, {
      path: "news",
      component: _2ffc9bb7,
      children: [{
        path: "",
        component: _e585860c,
        name: "index-news___ru"
      }, {
        path: ":detail",
        component: _24dc4698,
        name: "index-news-detail___ru"
      }]
    }, {
      path: "register",
      component: _219bdcf2,
      name: "index-register___ru"
    }, {
      path: "register-type",
      component: _30cc3670,
      name: "index-register-type___ru"
    }, {
      path: "reset-password",
      component: _e5688f86,
      name: "index-reset-password___ru"
    }, {
      path: "training",
      component: _13a4d841,
      name: "index-training___ru"
    }, {
      path: "user-enterprise",
      component: _5913ef22,
      name: "index-user-enterprise___ru"
    }, {
      path: "user-individual",
      component: _b65bc8b2,
      name: "index-user-individual___ru"
    }, {
      path: "training/detail_old",
      component: _0b532bda,
      name: "index-training-detail_old___ru"
    }, {
      path: "training/lesson",
      component: _1385ee4e,
      name: "index-training-lesson___ru"
    }, {
      path: "training/multiple",
      component: _0ac0911e,
      name: "index-training-multiple___ru"
    }, {
      path: "agri-finance/:detail?",
      component: _95df17a2,
      name: "index-agri-finance-detail___ru"
    }, {
      path: "marketplace/:detail",
      component: _322e4cf8,
      name: "index-marketplace-detail___ru"
    }, {
      path: "training/:detail",
      component: _a06ba7c2,
      name: "index-training-detail___ru"
    }]
  }, {
    path: "/uz",
    component: _4ff1a5db,
    children: [{
      path: "",
      component: _dfb9c7c4,
      name: "index___uz"
    }, {
      path: "about",
      component: _706b4159,
      name: "index-about___uz"
    }, {
      path: "advisory",
      component: _35e1809d,
      name: "index-advisory___uz"
    }, {
      path: "agri-business",
      component: _2a0322fc,
      children: [{
        path: "",
        component: _16107c7f,
        name: "index-agri-business___uz"
      }]
    }, {
      path: "agri-finance",
      component: _ea04375e,
      name: "index-agri-finance___uz"
    }, {
      path: "agri-market",
      component: _47683278,
      name: "index-agri-market___uz"
    }, {
      path: "agro-tools",
      component: _3668e7d0,
      name: "index-agro-tools___uz"
    }, {
      path: "chats",
      component: _78367c2a,
      name: "index-chats___uz"
    }, {
      path: "chats-old",
      component: _50696541,
      name: "index-chats-old___uz"
    }, {
      path: "consultant-enterprise",
      component: _238600bf,
      name: "index-consultant-enterprise___uz"
    }, {
      path: "consultant-individual",
      component: _163bd812,
      name: "index-consultant-individual___uz"
    }, {
      path: "content-provider",
      component: _328a7aae,
      name: "index-content-provider___uz"
    }, {
      path: "graph",
      component: _7a50169a,
      name: "index-graph___uz"
    }, {
      path: "login",
      component: _59cabf35,
      name: "index-login___uz"
    }, {
      path: "marketplace",
      component: _521d5b5a,
      name: "index-marketplace___uz"
    }, {
      path: "news",
      component: _2ffc9bb7,
      children: [{
        path: "",
        component: _e585860c,
        name: "index-news___uz"
      }, {
        path: ":detail",
        component: _24dc4698,
        name: "index-news-detail___uz"
      }]
    }, {
      path: "register",
      component: _219bdcf2,
      name: "index-register___uz"
    }, {
      path: "register-type",
      component: _30cc3670,
      name: "index-register-type___uz"
    }, {
      path: "reset-password",
      component: _e5688f86,
      name: "index-reset-password___uz"
    }, {
      path: "training",
      component: _13a4d841,
      name: "index-training___uz"
    }, {
      path: "user-enterprise",
      component: _5913ef22,
      name: "index-user-enterprise___uz"
    }, {
      path: "user-individual",
      component: _b65bc8b2,
      name: "index-user-individual___uz"
    }, {
      path: "training/detail_old",
      component: _0b532bda,
      name: "index-training-detail_old___uz"
    }, {
      path: "training/lesson",
      component: _1385ee4e,
      name: "index-training-lesson___uz"
    }, {
      path: "training/multiple",
      component: _0ac0911e,
      name: "index-training-multiple___uz"
    }, {
      path: "agri-finance/:detail?",
      component: _95df17a2,
      name: "index-agri-finance-detail___uz"
    }, {
      path: "marketplace/:detail",
      component: _322e4cf8,
      name: "index-marketplace-detail___uz"
    }, {
      path: "training/:detail",
      component: _a06ba7c2,
      name: "index-training-detail___uz"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
