var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "group w-full hover:shadow-md rounded-md shadow-sm relative",
    },
    [
      _c("div", { staticClass: "flex justify-center w-full" }, [
        _c("img", {
          staticClass:
            "w-full md:h-72 h-48 rounded-md object-cover group-hover:opacity-60",
          attrs: {
            src:
              _vm.data && _vm.data.avatar
                ? _vm.$tools.getFileUrl(_vm.data.avatar)
                : require("/assets/images/person/avatar.jpg"),
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "bg-white rounded-md inset-x-2 p-4 flex justify-center shadow-md absolute -bottom-7 z-20",
        },
        [
          _c("div", { staticStyle: { width: "100%" } }, [
            _c(
              "div",
              {
                staticClass:
                  "font-semibold text-gray-700 my-2 text-sm flex justify-center group-hover:justify-start",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.data.middlename
                        ? _vm.data.middlename
                        : `${_vm.data.name} ${_vm.data.surname}`
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "text-gray-500 text-xs text-center flex justify-center group-hover:hidden my-1",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.data.activitytypes[0]
                        ? _vm.checkLocalizations(_vm.data.activitytypes[0])
                        : _vm.data.otherarea
                        ? _vm.data.otherarea
                        : ""
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "group-hover:flex items-center hidden" }, [
              _c("span", { staticClass: "text-gray-500 text-xs" }, [
                _vm._v(_vm._s(_vm.$t("rating")) + ":  "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "bh-stars", attrs: { "data-bh-rating": "4.9" } },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "bh-star bh-star--1",
                      attrs: {
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        viewBox: "0 0 24 24",
                        "xml:space": "preserve",
                      },
                    },
                    [
                      _c("path", {
                        staticClass: "outline",
                        attrs: {
                          d: "M12,4.2L14.5,9l0.2,0.5l0.5,0.1l5.5,0.8L16.8,14l-0.4,0.4l0.1,0.5l1,5.3l-5-2.5L12,17.5l-0.4,0.2l-5,2.5L7.5,15l0.1-0.5 L7.2,14l-4-3.7l5.5-0.8l0.5-0.1L9.5,9L12,4.2 M11.9,2L8.6,8.6L1,9.7l5.5,5.1L5.2,22l6.8-3.4l6.8,3.4l-1.3-7.2L23,9.6l-7.6-1L11.9,2 L11.9,2z",
                        },
                      }),
                      _c("polygon", {
                        staticClass: "full",
                        attrs: {
                          points:
                            "18.8,22 12,18.6 5.2,22 6.5,14.8 1,9.7 8.6,8.6 11.9,2 15.4,8.6 23,9.6 17.5,14.7",
                        },
                      }),
                      _c("polyline", {
                        staticClass: "left-half",
                        attrs: {
                          points:
                            "12,18.6 5.2,22 6.5,14.8 1,9.7 8.6,8.6 11.9,2",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      staticClass: "bh-star bh-star--2",
                      attrs: {
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        viewBox: "0 0 24 24",
                        "xml:space": "preserve",
                      },
                    },
                    [
                      _c("path", {
                        staticClass: "outline",
                        attrs: {
                          d: "M12,4.2L14.5,9l0.2,0.5l0.5,0.1l5.5,0.8L16.8,14l-0.4,0.4l0.1,0.5l1,5.3l-5-2.5L12,17.5l-0.4,0.2l-5,2.5L7.5,15l0.1-0.5 L7.2,14l-4-3.7l5.5-0.8l0.5-0.1L9.5,9L12,4.2 M11.9,2L8.6,8.6L1,9.7l5.5,5.1L5.2,22l6.8-3.4l6.8,3.4l-1.3-7.2L23,9.6l-7.6-1L11.9,2 L11.9,2z",
                        },
                      }),
                      _c("polygon", {
                        staticClass: "full",
                        attrs: {
                          points:
                            "18.8,22 12,18.6 5.2,22 6.5,14.8 1,9.7 8.6,8.6 11.9,2 15.4,8.6 23,9.6 17.5,14.7",
                        },
                      }),
                      _c("polyline", {
                        staticClass: "left-half",
                        attrs: {
                          points:
                            "12,18.6 5.2,22 6.5,14.8 1,9.7 8.6,8.6 11.9,2",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      staticClass: "bh-star bh-star--3",
                      attrs: {
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        viewBox: "0 0 24 24",
                        "xml:space": "preserve",
                      },
                    },
                    [
                      _c("path", {
                        staticClass: "outline",
                        attrs: {
                          d: "M12,4.2L14.5,9l0.2,0.5l0.5,0.1l5.5,0.8L16.8,14l-0.4,0.4l0.1,0.5l1,5.3l-5-2.5L12,17.5l-0.4,0.2l-5,2.5L7.5,15l0.1-0.5 L7.2,14l-4-3.7l5.5-0.8l0.5-0.1L9.5,9L12,4.2 M11.9,2L8.6,8.6L1,9.7l5.5,5.1L5.2,22l6.8-3.4l6.8,3.4l-1.3-7.2L23,9.6l-7.6-1L11.9,2 L11.9,2z",
                        },
                      }),
                      _c("polygon", {
                        staticClass: "full",
                        attrs: {
                          points:
                            "18.8,22 12,18.6 5.2,22 6.5,14.8 1,9.7 8.6,8.6 11.9,2 15.4,8.6 23,9.6 17.5,14.7",
                        },
                      }),
                      _c("polyline", {
                        staticClass: "left-half",
                        attrs: {
                          points:
                            "12,18.6 5.2,22 6.5,14.8 1,9.7 8.6,8.6 11.9,2",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      staticClass: "bh-star bh-star--4",
                      attrs: {
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        viewBox: "0 0 24 24",
                        "xml:space": "preserve",
                      },
                    },
                    [
                      _c("path", {
                        staticClass: "outline",
                        attrs: {
                          d: "M12,4.2L14.5,9l0.2,0.5l0.5,0.1l5.5,0.8L16.8,14l-0.4,0.4l0.1,0.5l1,5.3l-5-2.5L12,17.5l-0.4,0.2l-5,2.5L7.5,15l0.1-0.5 L7.2,14l-4-3.7l5.5-0.8l0.5-0.1L9.5,9L12,4.2 M11.9,2L8.6,8.6L1,9.7l5.5,5.1L5.2,22l6.8-3.4l6.8,3.4l-1.3-7.2L23,9.6l-7.6-1L11.9,2 L11.9,2z",
                        },
                      }),
                      _c("polygon", {
                        staticClass: "full",
                        attrs: {
                          points:
                            "18.8,22 12,18.6 5.2,22 6.5,14.8 1,9.7 8.6,8.6 11.9,2 15.4,8.6 23,9.6 17.5,14.7",
                        },
                      }),
                      _c("polyline", {
                        staticClass: "left-half",
                        attrs: {
                          points:
                            "12,18.6 5.2,22 6.5,14.8 1,9.7 8.6,8.6 11.9,2",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      staticClass: "bh-star bh-star--5",
                      attrs: {
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        viewBox: "0 0 24 24",
                        "xml:space": "preserve",
                      },
                    },
                    [
                      _c("path", {
                        staticClass: "outline",
                        attrs: {
                          d: "M12,4.2L14.5,9l0.2,0.5l0.5,0.1l5.5,0.8L16.8,14l-0.4,0.4l0.1,0.5l1,5.3l-5-2.5L12,17.5l-0.4,0.2l-5,2.5L7.5,15l0.1-0.5 L7.2,14l-4-3.7l5.5-0.8l0.5-0.1L9.5,9L12,4.2 M11.9,2L8.6,8.6L1,9.7l5.5,5.1L5.2,22l6.8-3.4l6.8,3.4l-1.3-7.2L23,9.6l-7.6-1L11.9,2 L11.9,2z",
                        },
                      }),
                      _c("polygon", {
                        staticClass: "full",
                        attrs: {
                          points:
                            "18.8,22 12,18.6 5.2,22 6.5,14.8 1,9.7 8.6,8.6 11.9,2 15.4,8.6 23,9.6 17.5,14.7",
                        },
                      }),
                      _c("polyline", {
                        staticClass: "left-half",
                        attrs: {
                          points:
                            "12,18.6 5.2,22 6.5,14.8 1,9.7 8.6,8.6 11.9,2",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.data.additionalinfo && _vm.data.additionalinfo.last_degree
              ? _c(
                  "div",
                  {
                    staticClass:
                      "text-gray-500 text-xs text-center hidden group-hover:flex my-1",
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("degree")) + ":  ")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.data.additionalinfo.last_degree) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.data.agrocultureareas[0] || _vm.data.env_otherarea
              ? _c(
                  "div",
                  {
                    staticClass:
                      "text-gray-500 text-xs text-center hidden my-1 group-hover:flex",
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("area-of-agriculture")) + ":  "),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.data.env_otherarea
                            ? _vm.data.env_otherarea
                            : _vm.data.agrocultureareas[0]
                            ? _vm.checkLocalizations(_vm.data.agrocultureareas)
                            : ""
                        ) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.data.activitytypes[0] || _vm.data.otherarea
              ? _c(
                  "div",
                  {
                    staticClass:
                      "text-gray-500 text-xs text-center hidden group-hover:flex my-1",
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("area-of-consultancy")) + ":  "),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.data.otherarea
                            ? _vm.data.otherarea
                            : _vm.data.activitytypes[0]
                            ? _vm.checkLocalizations(_vm.data.activitytypes)
                            : ""
                        ) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isConsultant
              ? _c(
                  "button",
                  {
                    staticClass:
                      "bg-[#169D23] rounded-md text-white text-xs py-2 px-5 mt-3 mx-auto hidden group-hover:flex transition delay-75 focus:outline-none duration-500",
                    on: {
                      click: function ($event) {
                        return _vm.toGetConsultation()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("get-consultation")) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }