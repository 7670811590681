var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-4" }, [
    _c(
      "div",
      {
        staticClass:
          "flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600",
      },
      [
        _c(
          "h3",
          {
            staticClass:
              "text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white",
          },
          [_vm._v("\n        " + _vm._s(_vm.$t("image")) + "\n      ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white",
            attrs: { type: "button", "data-modal-toggle": "defaultModal" },
            on: {
              click: function ($event) {
                return _vm.onClose("canceled")
              },
            },
          },
          [
            _c(
              "svg",
              {
                staticClass: "w-5 h-5",
                attrs: {
                  fill: "currentColor",
                  viewBox: "0 0 20 20",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                    "clip-rule": "evenodd",
                  },
                }),
              ]
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { position: "relative" } },
      [
        _c("div", { staticClass: "image_progress" }, [
          _vm.uploading.show &&
          _vm.uploading.percent > 0 &&
          _vm.uploading.percent < 100
            ? _c("div", { staticClass: "shadow w-full bg-grey-light mt-2" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-teal text-xs leading-none py-1 text-center text-white",
                    style: `width: ${_vm.uploading.percent}%`,
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.uploading.percent) +
                        "%\n          "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.img
          ? _c("div", { staticClass: "toolbar" }, [
              _c(
                "span",
                {
                  staticClass: "font-medium icon",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.cropper.zoom(2)
                    },
                  },
                },
                [_c("i", { staticClass: "bx bx-plus-circle" })]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "font-medium icon",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.cropper.zoom(0.5)
                    },
                  },
                },
                [_c("i", { staticClass: "bx bx-minus-circle" })]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "font-medium icon",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.cropper.rotate(-90)
                    },
                  },
                },
                [_c("i", { staticClass: "bx bx-rotate-left" })]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "font-medium icon",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.cropper.rotate(90)
                    },
                  },
                },
                [_c("i", { staticClass: "bx bx-rotate-right" })]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "font-medium icon",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.cropper.flip(true)
                    },
                  },
                },
                [_c("i", { staticClass: "bx bx-horizontal-center" })]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "font-medium icon",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.cropper.flip(false, true)
                    },
                  },
                },
                [_c("i", { staticClass: "bx bx-vertical-center" })]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "font-medium icon", on: { click: _vm.center } },
                [_c("i", { staticClass: "bx bx-collapse" })]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "font-medium icon", on: { click: _vm.full } },
                [_c("i", { staticClass: "bx bx-expand" })]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.img
          ? _c("div", { staticClass: "crop_placeholder" }, [
              _c("img", { attrs: { src: _vm.image, alt: "" } }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.img
          ? _c("cropper", {
              ref: "cropper",
              staticClass: "cropper",
              attrs: {
                src: _vm.img,
                "auto-zoom": true,
                "stencil-size": _vm.settings.stencil.size,
                "image-restriction": "stencil",
                "stencil-props": _vm.settings.stencil.props,
              },
              on: { change: _vm.change },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "flex p-6 s space-x-2 w-full rounded-b border-t border-gray-200 dark:border-gray-600",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-[#169D23] bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#169D23]",
            on: {
              click: function ($event) {
                return _vm.$refs.file.click()
              },
            },
          },
          [_vm._v("\n        " + _vm._s(_vm.$t("upload")) + "\n      ")]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { display: "flex" } }, [
          _c(
            "button",
            {
              staticClass:
                "mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
              on: { click: _vm.hide },
            },
            [_vm._v("\n          " + _vm._s(_vm.$t("cancel")) + "\n        ")]
          ),
          _vm._v(" "),
          _vm.img
            ? _c(
                "button",
                {
                  staticClass:
                    "mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
                  on: {
                    click: function ($event) {
                      _vm.img = null
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("clear")) + "\n        "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.img
            ? _c(
                "button",
                {
                  staticClass:
                    "mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-[#169D23] bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#169D23]",
                  on: { click: _vm.uploadImage },
                },
                [_vm._v("\n          " + _vm._s(_vm.$t("save")) + "\n        ")]
              )
            : _vm._e(),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("input", {
      ref: "file",
      staticStyle: { display: "none" },
      attrs: { type: "file", accept: "image/*" },
      on: { change: _vm.loadImage },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }