var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "swiper",
        {
          ref: "swiper",
          staticClass: "swiper",
          attrs: {
            options: _vm.categoriesOption,
            autoplay: { delay: 2000, disableOnIteraction: false },
            pagination: { clickable: true },
          },
        },
        [
          _c(
            "client-only",
            _vm._l(_vm.data, function (category, index) {
              return _c("swiper-slide", { key: index }, [
                _c(
                  "button",
                  {
                    staticClass: "rounded-md py-1.5 mb-4 w-full",
                    class:
                      _vm.$route.query.category &&
                      parseInt(_vm.$route.query.category) === category.id
                        ? "bg-[#169D23] text-white"
                        : "border border-[#169D23] text-[#169D23] hover:text-white hover:bg-[#169D23]",
                    on: {
                      click: function ($event) {
                        return _vm.toChange(category)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          category.attributes ? category.attributes.name : ""
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ])
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }